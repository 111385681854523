.App {
  text-align: center;
  color: #282c34;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: white;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

a.primary {
  color: #dc3545 !important;
  &:hover,
  &:active {
    color: #c82333 !important;
  }
}

.perks {
  margin-top: 50px;
  h3 {
    margin-top: 20px;
  }
  p {
    margin-top: 10px;
  }
  img {
    object-fit: contain;
    width: 200px;
    height: 200px;
  }
  .swag {
    background-color: #ffc523;
  }
  .pot {
    background-color: #4d4a43;
  }
}

.details {
  p {
    font-size: 1.25rem;
    text-align: justify;
  }
}

.sponsors {
  img {
    object-fit: contain;

    &.horizontal {
      height: 200px;
      max-width: 300px;
    }

    &.vertical {
      height: 200px;
      max-width: 150px;
    }
  }
  p {
    font-size: calc(10px + 2vmin);
  }
}

.donations {
  img {
    object-fit: cover;
    height: 150px;
    width: 150px;
  }
  p {
    font-size: calc(10px + 2vmin);
  }
}

/* DETAILS CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.details .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.details h2 {
  font-weight: 400;
}
.details .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the details headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.05rem;
}

.featurette-image {
  width: 100%;
  height: 500px;
  object-fit: contain;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 2rem;
  }
}

.workshops {
  margin-top: 50px;
  .subtitle {
    font-size: calc(10px + 2vmin);
  }
}
.card-img-top {
  object-fit: contain;
}

.tba {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
